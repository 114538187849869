
import Vue_ from "vue";
// eslint-disable-next-line no-unused-vars
import { VueConstructor } from "vue/types/umd";
import { E2EModule } from "../__test__";
const Vue = Vue_ as VueConstructor<Vue_ & { spaParent: ISpaParent }>;
export default Vue.extend({
  name: "ArchivePlayer",
  props: {
    camera: Object,
    toggleArchivePlayer: Function,
  },
  data() {
    return {
      unmount: () => {},
      cyAttrs: {
        ...E2EModule.attributes,
      },
    };
  },
  inject: ["spaParent"],
  methods: {
    async mountPlayer() {
      const ref = this.$refs["ArchivePlayer"];
      const archivePlayer = this.spaParent.areas.find(
        (e: any) => e.name == "ArchivePlayer"
      );
      if (archivePlayer !== undefined) {
        this.unmount = this.spaParent.mountChildren(
          ref,
          archivePlayer.children.map((e: any) => {
            return {
              ...e,
              props: {
                ...e.props,
                camera: { id: this.camera.id },
                height: "calc(100vh - 150px)",
              },
            };
          })
        );
      }
    },
  },
  mounted() {
    this.mountPlayer();
  },
  destroyed() {
    this.unmount();
  },
});
