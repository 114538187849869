import { render, staticRenderFns } from "./AssignmentGroupEditor.vue?vue&type=template&id=44f6d5f5&scoped=true&"
import script from "./AssignmentGroupEditor.vue?vue&type=script&lang=ts&"
export * from "./AssignmentGroupEditor.vue?vue&type=script&lang=ts&"
import style0 from "./AssignmentGroupEditor.vue?vue&type=style&index=0&id=44f6d5f5&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44f6d5f5",
  null
  
)

export default component.exports