
import Vue from "vue";

export default Vue.extend({
  name: "WidgetAdapter",
  props: {
    widgetName: String,
    props: Object as () => Record<string, unknown>,
    onMount: Function,
  },
  data() {
    return {
      mountPlace: null as HTMLElement | null,
      unmount: () => {},
    };
  },
  inject: ["spaParent"],
  computed: {
    isPropsProvided(): boolean {
      return !!this.props;
    },
  },
  mounted() {
    this.mountPlace = this.$refs.mountPlace as HTMLElement;
    // @ts-ignore
    const mountChildren = this.spaParent.mountChildren;
    // @ts-ignore
    const areas = this.spaParent.areas.find(
      (e: any) => e.name === this.widgetName
    );
    if (areas && mountChildren) {
      this.unmount = mountChildren(
        this.mountPlace,
        areas.children.map((e: any) => {
          return {
            ...e,
            props: {
              ...(e.props || {}),
              ...(this.props || {}),
              onClose: () => {
                this.$emit("close");
              },
            },
            onMount: this.onMount,
          };
        })
      );
    }
  },
  destroyed() {
    this.unmount();
  },
});
